import canUseDom from "@ecom/ui/utils/canUseDom"

function decodeValue(value: string) {
  try {
    return decodeURIComponent(value.replace(/\+/g, " "))
  } catch (error) {
    console.error("Decode error:", error)
    return value
  }
}

export function getSearchParam(key: string) {
  try {
    const search = canUseDom ? window?.location?.search : ""
    const searchParams = new URLSearchParams(search)
    const value = searchParams.get(key)

    if (value === null) {
      return ""
    }

    return decodeValue(value)
  } catch (error) {
    return ""
  }
}
