// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--9bQcp";
export var btnBlock = "banner-module--btnBlock--uYDsl";
export var container = "banner-module--container--Gle3P";
export var hideMobBlock = "banner-module--hideMobBlock--iC4lv";
export var img = "banner-module--img--2UVY3";
export var mobBtn = "banner-module--mobBtn--FROdI";
export var red = "banner-module--red--bJUaH";
export var secondContainer = "banner-module--secondContainer--axT97";
export var section = "banner-module--section--202nQ";
export var text = "banner-module--text--Ixdap";