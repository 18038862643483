type DataType = {
  admin: {
    variables: {
      items: {
        name: string
        value: string
      }[]
    }
  }
}

export type QueryTypePsk =
  | {
      name: string
      value: string
    }
  | undefined

export const getVariableFromQuery = (queryKey: string, adminData: DataType): QueryTypePsk => {
  const queryPsk = adminData.admin.variables.items.find((item) => item.name === queryKey)

  if (queryPsk) {
    const result: QueryTypePsk = {
      name: queryPsk.name,
      value: queryPsk.value,
    }
    return result
  }

  return undefined
}
