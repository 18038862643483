import React from "react"
import clsx from "clsx"

import type { ReactNode } from "react"

import * as styles from "./GradientBorder.module.scss"

type Props = {
  children?: ReactNode
  className?: string
}

const GradientBorder = ({ children, className }: Props) => (
  <div className={clsx(styles.wrapper, className)}>{children}</div>
)

export default GradientBorder
