import React, { FC, type ReactNode } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import { GatsbyImageProps } from "gatsby-plugin-image"
import { GiftImage } from "./img/GiftImage"
import { HalvaCardImg } from "./img/halvaCard"
import { PhoneImg } from "./img/PhoneImg"
import { Sticker } from "./img/Sticker"

import * as styles from "./BottomCta.module.scss"
import { GradientBorder } from "../GradientBorder"
import { BallsImg } from "./img/BallsImg"
import { handleClickSwitcherContent } from "../../helpers/WatcherDL/utils/handleClickSwitcherContent"

const DEFAULT_TITLE = (
  <>
    Оформите Халву и получите <br /> <b> месяц подписки в подарок</b>
  </>
)

interface BottomCtaProps {
  buttonText?: string
  title?: ReactNode
  variant?: "refin" | "halva" | "pkwRedesign" | "refinRedesign" | "newYear" | "inviteFriend"
  orderNum?: string
  BgImg: FC<Omit<GatsbyImageProps, "image">>
  StickerPay?: boolean
  newYear?: boolean
}

export const BottomCta = ({
  buttonText = "Оформить карту",
  title = DEFAULT_TITLE,
  variant = "halva",
  orderNum,
  BgImg,
  StickerPay = false,
  newYear = false,
}: BottomCtaProps) => (
  <section id="bottomCta" className={clsx(styles.root, styles[variant])} data-exclude={orderNum}>
    <Container className={styles.container}>
      <div className={clsx(styles.wrapper, styles[variant])}>
        <BgImg alt="background image" className={styles.img} />
        {!StickerPay && (
          <HalvaCardImg
            alt="halvaCard"
            className={clsx(styles.halvaCardImgMobile, styles[variant])}
          />
        )}
        <GradientBorder className={clsx(styles.wrap, styles[variant])}>
          {StickerPay ? (
            <>
              <PhoneImg alt="phone" className={styles.phoneImg} />
              <Sticker alt="sticker" className={styles.stickerImg} />
            </>
          ) : (
            <>
              {!newYear ? (
                <GiftImage alt="gift" className={clsx(styles.giftImg, styles[variant])} />
              ) : (
                <BallsImg alt="gift" className={styles.ballsImg} />
              )}

              <HalvaCardImg
                alt="halvaCard"
                className={clsx(styles.halvaCardImg, styles[variant])}
              />
            </>
          )}
          <p className={clsx(styles.text, styles[variant])}>{title}</p>
          <Button
            className={clsx(styles.btn, styles[variant])}
            onClick={() => handleClickSwitcherContent("click_cta")}
          >
            {buttonText}
          </Button>
        </GradientBorder>
      </div>
    </Container>
  </section>
)
