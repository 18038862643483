// extracted by mini-css-extract-plugin
export var ballsImg = "BottomCta-module--ballsImg--jY8MY";
export var btn = "BottomCta-module--btn--voAAa";
export var container = "BottomCta-module--container--Y4plt";
export var giftImg = "BottomCta-module--giftImg--wNXyF";
export var halva = "BottomCta-module--halva--U7tP0";
export var halvaCardImg = "BottomCta-module--halvaCardImg--zgRgk";
export var halvaCardImgMobile = "BottomCta-module--halvaCardImgMobile--Nxyk9";
export var img = "BottomCta-module--img--+axnZ";
export var inviteFriend = "BottomCta-module--inviteFriend--NKBRz";
export var newYear = "BottomCta-module--newYear--sFIfR";
export var phoneImg = "BottomCta-module--phoneImg--LqfIr";
export var pkwRedesign = "BottomCta-module--pkwRedesign--q+xH1";
export var refin = "BottomCta-module--refin--EfW1m";
export var refinRedesign = "BottomCta-module--refinRedesign--YBVO-";
export var root = "BottomCta-module--root--olrjm";
export var section = "BottomCta-module--section--J1vEg";
export var stickerImg = "BottomCta-module--stickerImg--m9Q-T";
export var text = "BottomCta-module--text--XupXu";
export var wrap = "BottomCta-module--wrap--zMwAO";
export var wrapper = "BottomCta-module--wrapper--YvEhG";